import { Modal } from "@mui/material";
import styles from "./index.module.css";
import React from "react";
import { onGotoApp } from "../../App";

const QuaAppDownDialog = ({ visible, onGotoApp, onClose, children }) => {
  const handleOnDownload = async () => {
    await onClose();
    onGotoApp("/");
  };
  return (
    <Modal open={visible} className={styles.modal}>
      <div className={styles.dialog}>
        <img
          className={styles.logo}
          src="/img/ic-appicon-download-75.png"
          srcSet="/img/ic-appicon-download-75@2x.png 2x,
                  /img/ic-appicon-download-75@3x.png 3x"
        />
        <p className={`${styles.message} Textstyle_2`}>{children}</p>
        <button
          className={`${styles.btn_down} Textstyle_3`}
          onClick={handleOnDownload}
        >
          쿠아바타 앱 다운 받기
        </button>
        <button
          className={`${styles.btn_cancel} Textstyle_7`}
          onClick={onClose}
        >
          나중에 할래요.
        </button>
      </div>
    </Modal>
  );
};

export const QuaAppGuideDialog = ({ dialog }) => {
  const [shownDialog, setShownDialog] = React.useState(false);
  const [appLinkPath, setAppLinkPath] = React.useState("/");

  if (dialog) {
    dialog.open = (appLinkPath) => {
      setShownDialog(true);
      appLinkPath && setAppLinkPath(appLinkPath);
    };
  }

  return (
    <QuaAppDownDialog
      visible={shownDialog}
      onGotoApp={() => onGotoApp(appLinkPath)}
      onClose={() => setShownDialog(false)}
    >
      앱을 설치하면 나만의 AI 아바타를 만들고
      <br />
      채팅, SNS 등 다양한
      <br />
      기능을 통해 소통할 수 있어요!
    </QuaAppDownDialog>
  );
};

export const QuaServiceDialog = ({ dialog }) => {
  const [shownDialog, setShownDialog] = React.useState(false);
  const [appLinkPath, setAppLinkPath] = React.useState("/");

  if (dialog) {
    dialog.open = (appLinkPath) => {
      setShownDialog(true);
      appLinkPath && setAppLinkPath(appLinkPath);
    };
  }

  return (
    <QuaAppDownDialog
      visible={shownDialog}
      onGotoApp={() => onGotoApp(appLinkPath)}
      onClose={() => setShownDialog(false)}
    >
      쿠아바타 앱에서
      <br />
      AI 아바타와 대화할 수 있어요.
    </QuaAppDownDialog>
  );
};

export const QuaCommuniDialog = ({ dialog }) => {
  const [shownDialog, setShownDialog] = React.useState(false);
  const [appLinkPath, setAppLinkPath] = React.useState("/");

  if (dialog) {
    dialog.open = (appLinkPath) => {
      setShownDialog(true);
      appLinkPath && setAppLinkPath(appLinkPath);
    };
  }

  return (
    <QuaAppDownDialog
      visible={shownDialog}
      onGotoApp={() => onGotoApp(appLinkPath)}
      onClose={() => setShownDialog(false)}
    >
      쿠아바타 앱에서
      <br />
      회원님의 생각을 공유해 주세요.
    </QuaAppDownDialog>
  );
};

export default QuaAppDownDialog;
