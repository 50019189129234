import styles from './index.module.css';
import React from 'react';
import { resolveTalk } from "../../common/talk";

function MyTalkBox(props) {
  const { message } = props;
  const [contents, setContents] = React.useState([]);
  const [urls, setUrls] = React.useState([]);
  const [imgUrls, setImgUrls] = React.useState([]);

  React.useEffect(() => {
    async function _resolveTalk(message) {
      const { contents, urls, imgUrls } = await resolveTalk(message);
      setContents(contents);
      setUrls(urls);
      setImgUrls(imgUrls); 
    }
    _resolveTalk(message);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
      {contents.map((content, index) => {
          return (
            <span key={index} className={`${styles.text} Textstyle_7`}>
              {content}
              {urls && urls[index] && (
                <a href={urls[index]} target="_blank" rel="noreferrer">
                  {urls[index]}
                </a>
              )}
              {imgUrls && imgUrls[index] && (
                <img
                  alt="img"
                  src={imgUrls[index]}
                  className={styles.img}
                ></img>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default MyTalkBox;
