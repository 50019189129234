export function sharePick(pick) {
  if (navigator.share) {
    navigator
      .share({
        title: `쿠아바타::Quavatar`,
        text: `${pick.title}`,
        url: `https://m.quavatar.com/pick/${pick.pickSeqNo}`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    console.log("Share not supported on this browser, do it the old way.");
  }
}
