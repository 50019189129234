import styles from "./index.module.css";
import React from "react";

const QuaAppViewSheet = ({ visible, onGotoApp, onStayHere }) => {

  return visible ? (
    <div className={styles.main}>
      <img
        src="/img/ic-appicon-download-75.png"
        srcSet="/img/ic-appicon-download-75@2x.png 2x,
                /img/ic-appicon-download-75@3x.png 3x"
      />
      <p className={`${styles.title} Textstyle_2`}>
        쿠아바타 앱에서 AI와의 대화로 만든
        <br />더 많은 콘텐츠를 확인해 보세요!
      </p>
      <button
        className={`${styles.btn_app} Textstyle_3`}
        type="button"
        onClick={onGotoApp}
      >
        쿠아바타 앱으로 보기
      </button>
      <button
        className={`${styles.btn_web} Textstyle_7`}
        type="button"
        onClick={onStayHere}
      >
        괜찮아요, 모바일 웹으로 볼게요.
      </button>
    </div>
  ) : (
    <></>
  );
};

export default QuaAppViewSheet;
