import { dialogActionsClasses } from "@mui/material";
import styles from "./QuaPickTileHeader.module.css";
import React from "react";

function QuaPickTileHeader({
  title,
  serviceOwnerName,
  serviceName,
  avatarImg,
  avatarName,
  pickTime,
  onServiceAction,
}) {
  let dotIdx = avatarImg.lastIndexOf(".");
  let avatarImgSet = "";
  avatarImgSet = dotIdx
    ? `${avatarImg.slice(0, dotIdx)}@2x${avatarImg.slice(dotIdx)} 2x, ` +
      `${avatarImg.slice(0, dotIdx)}@3x${avatarImg.slice(dotIdx)} 3x`
    : "";
  return (
    <>
      <div className={styles.pick_title}>
        <h1 className="Textstyle_3">{title}</h1>
      </div>
      <div className={`${styles.service_info} Textstyle_8`}>
        <img src="/img/ic-arrow-right-color5-12.svg" />
        <span className={`${styles.ellipse}`} onClick={onServiceAction}>
          {serviceOwnerName}
        </span>
        <span className={`${styles.sp}`} onClick={onServiceAction}>
          님의
        </span>
        <span className={`${styles.ellipse}`} onClick={onServiceAction}>
          {serviceName}
        </span>
        <span onClick={onServiceAction}>&nbsp;사용</span>
      </div>
      <div className={styles.pick_writer}>
        <img
          alt="avatar"
          className={styles.avatar_img}
          src={avatarImg}
          srcSet={avatarImgSet}
        />
        <div className={styles.avatar_text}>
          <span className={`${styles.avatarid} Textstyle_7`}>{avatarName}</span>
          <span className={`${styles.date} Textstyle_8`}>{pickTime}</span>
        </div>
      </div>
    </>
  );
}

export default QuaPickTileHeader;
