const About = () => {
  return (
    <div>
      <h1>소개</h1>
      <p>쿠아바타</p>
    </div>
  );
};

export default About;
