import axios from "axios";

export async function resolveTalk(message) {
  const urlPattern =
    /(?:https?):\/\/(?:[\w-]+\.)+[a-z]{2,6}(?:\/(?:[\w~+^\-@:%=&.,]*[\w~+^\-@%=])?)*(?:[?][\w~+^\-@:;%?=&.,]*)*/gm;
  const contents = [message]; //message.split(urlPattern);
  const urls = null; //message.match(urlPattern);
  const imgUrls = [];

  if(urls)
    (await Promise.all(
      urls.map(async (url, index) => {
        urls[index] = null;
        let res;
        try{
          res = await axios.head(url);
        } catch(e){
          res = null;
        }
        if (
          res &&
          res.status === 200 &&
          res.headers["content-type"].includes("image")
        ) {
          imgUrls[index] = url;
        } else {
          urls[index] = url;
        }
      })
    ));

  return { contents, urls, imgUrls };
}
