import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import QuaPlace from "./pages/QuaPlace";
import QuaPick from "./pages/QuaPick";
import NotFound from "./pages/NoutFound";
import QuaPickNotFound from "./pages/QuaPickNotFound";
import AppContext from "./context/AppContext";

const checkMobile = () => {
  var e = navigator.userAgent.toLowerCase();
  return e.indexOf("android") > -1
    ? "android"
    : e.indexOf("iphone") > -1 ||
      e.indexOf("ipad") > -1 ||
      e.indexOf("ipod") > -1
    ? "ios"
    : window.navigator.platform.toLowerCase().indexOf("mac") > -1
    ? "mac"
    : "pc";
};

export function onGotoApp(appLinkPath) {
  const packageName = "com.eoground.quavatar";
  const domainName = "link.quavatar.com";

  if (!appLinkPath) appLinkPath = "/";
  if (appLinkPath[0] !== "/") appLinkPath = "/" + appLinkPath;

  switch (checkMobile()) {
    case "android":
      window.location.href = `intent://${domainName}/${appLinkPath}#Intent;package=${packageName};scheme=https;end`;
      break;
    case "ios":
    case "mac":
      window.location.href = `https://${domainName}/${appLinkPath}`;
      break;
    default:
      window.location.href = `https://play.google.com/store/apps/details?id=${packageName}`;
  }
}

function App() {
  return (
    <AppContext.Provider value={{ webMode: false, viewCount: 0 }}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/place" element={<QuaPlace />} />
        <Route path="/place/:seq_no" element={<QuaPlace />} />
        <Route path="/pick/:seq_no" element={<QuaPick />} />
        <Route path="/pick/not-found" element={<QuaPickNotFound />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
