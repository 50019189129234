import styles from "./index.module.css";
import React from "react";

import { formatDate } from "../../common/date";

import QuaPickTileHeader from "./QuaPickTileHeader";
import QuaPickTileBody from "./QuaPickTileBody";
import QuaPickTileFooter from "./QuaPickTileFooter";

function QuaPickTile({
  tile,
  pickType,
  onClick,
  onServiceAction,
  onLikeAction,
  onCommentAction,
  onShareAction,
}) {
  return (
    <div
      className={
        pickType === "large" ? styles.pick_tile_large : styles.pick_tile_normal
      }
    >
      <QuaPickTileHeader
        title={tile.title}
        serviceOwnerName={tile.sowner && tile.sowner.anm}
        serviceName={tile.snm}
        avatarImg={tile.aimg}
        avatarName={tile.anm}
        pickTime={formatDate(tile.when)}
        onServiceAction={onServiceAction}
      />
      <QuaPickTileBody
        chats={tile.chats}
        pickType={pickType}
        onClick={onClick}
      />
      <QuaPickTileFooter
        seqNo={tile.pickSeqNo}
        likeNum={tile.like_num}
        commentNum={tile.comment_num}
        onLikeAction={onLikeAction}
        onCommentAction={onCommentAction}
        onShareAction={onShareAction}
      />
    </div>
  );
}

export default QuaPickTile;
