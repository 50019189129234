import { useNavigate } from "react-router-dom";
import styles from "./QuaPickNotFound.module.css";
import React from "react";

const QuaPickNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.ground}>
      <div className={styles.body}>
        <div className={styles.inform}>
          <h2 className={`Textstyle_2`}>페이지에 연결할 수 없어요.</h2>
          <p className={`Textstyle_4`}>
            삭제된 게시물이거나
            <br />더 이상 이용할 수 없는 주소예요.
          </p>
          <button className={`Textstyle_3`} onClick={() => navigate("/place")}>
            쿠아바타에서 다른 게시물 보기
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuaPickNotFound;
