import styles from "./QuaPlace.module.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import QuaPickTile from "../components/QuaPickTile";
import BottomSheet from "../components/BottomSheet";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import QuaAppViewSheet from "../components/QuaAppViewSheet";
import AppContext from "../context/AppContext";
import { onGotoApp } from "../App";
import {
  QuaServiceDialog,
  QuaCommuniDialog,
  QuaAppGuideDialog,
} from "../components/QuaAppDownDialog";
import { sharePick } from "../common/share";
import QuaAppBar from "../components/QuaAppBar";

const QuaPlaceBody = ({
  serviceDialog,
  likeDialog,
  commentDialog,
  onShowAd,
}) => {
  const navigate = useNavigate();
  const { seq_no } = useParams();
  const lastPickSeqNo = useRef("");
  const [picks, _setPicks] = useState([]);
  const picksRef = useRef(picks);
  const setPicks = (data) => {
    picksRef.current = data;
    _setPicks(data);
  };
  const [avatarIdMap, _setAvatarIdMap] = useState({});
  const avatarIdMapRef = useRef(avatarIdMap);
  const setAvatarIdMap = (data) => {
    avatarIdMapRef.current = data;
    _setAvatarIdMap(data);
  };
  const loadingMoreApi = useRef(false);

  const observerRef = useRef(null);
  const picksWrapRef = useRef(null);
  const [checkedIndices] = useState([]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elm = entry.target;
            observerRef.current.unobserve(elm);
            const data = elm.getAttribute("data");
            if (!checkedIndices.includes(data)) {
              checkedIndices.push(data);
              onShowAd(data);
            }
          }
        }),
      { threshold: 1, rootMargin: "0px 0px -87px" }
    );
  }, []);

  useEffect(() => {
    return () => {
      observerRef.current.disconnect();
    };
  }, [picksWrapRef]);

  function clickHandler(pick) {
    sessionStorage.setItem("scrollPosition", window.scrollY);
    navigate("/pick/" + pick.pickSeqNo);
  }

  const onServiceActionHandler = (pick) => {
    serviceDialog.open(`/eg_place/${pick.pickSeqNo}`);
  };
  const onLikeActionHandler = (pick) => {
    likeDialog.open(`/eg_place/${pick.pickSeqNo}`);
  };
  const onCommentActionHandler = (pick) => {
    commentDialog.open(`/eg_place/${pick.pickSeqNo}`);
  };

  useEffect(() => {
    const fetchPicks = async (handler) => {
      if (loadingMoreApi.current) return;
      loadingMoreApi.current = true;
      const apiUri = seq_no
        ? `https://api-dev.quavatar.com/smiled/pick-list?startAt=${seq_no}&limit=1`
        : `https://api-dev.quavatar.com/smiled/pick-list?startFrom=${lastPickSeqNo.current}`;
      try {
        const response = await axios.get(apiUri);
        const stdDto = response.data;
        if (stdDto.resultCode === "EO101") {
          handler(stdDto.result);
        } else {
          console.error(stdDto.resultCode);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const loadedQuaPickList = async (pickList) => {
      const _avatarIdMap = {};
      pickList.map((pick) =>
        pick.chats
          .filter((chat) => !(chat.aid in avatarIdMapRef.current))
          .map(
            (chat) =>
              !(chat.aid in _avatarIdMap) &&
              (_avatarIdMap[chat.aid] = { aid: chat.aid, anm: chat.anm })
          )
      );
      setAvatarIdMap({ ...avatarIdMapRef.current, ..._avatarIdMap });
      setPicks([
        ...picksRef.current,
        ...pickList.map((pick) => {
          const so = { aid: 1 * pick.sid };
          lastPickSeqNo.current = pick.pickSeqNo;
          return {
            ...pick,
            sowner: avatarIdMapRef.current[so.aid],
            aimg: "/img/avatar-cli.png",
            chats: pick.chats.map((chat) => {
              return {
                ...chat,
                itsme: chat.aid === pick.aid,
              };
            }),
          };
        }),
      ]);
      loadingMoreApi.current = false;

      const scrollPos = sessionStorage.getItem("scrollPosition");
      if (scrollPos) {
        sessionStorage.removeItem("scrollPosition"); // clear the value
        setTimeout(() => {
          window.scrollTo(0, parseInt(scrollPos));
        }, 1);
      }
    };

    const scrollEvent = () => {
      const scrollThreshold = 370 * 0.2;
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      if (
        !loadingMoreApi.current &&
        scrollTop + clientHeight >= scrollHeight - scrollThreshold
      ) {
        fetchPicks(loadedQuaPickList);
      }
    };

    fetchPicks(loadedQuaPickList);

    !seq_no && window.addEventListener("scroll", scrollEvent);
    return () => {
      !seq_no && window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <div className={styles.qua_place_cont} ref={picksWrapRef}>
      {picks.map((pick, index) => (
        <div
          data={pick.pickSeqNo}
          key={index}
          ref={(elm) => {
            elm &&
              observerRef.current &&
              (index + 1) % 5 === 0 &&
              observerRef.current.observe(elm);
          }}
        >
          {index > 0 && <hr className={styles.qua_pick_sp} />}
          <QuaPickTile
            tile={pick}
            pickType={seq_no ? "large" : "normal"}
            onClick={() => clickHandler(pick)}
            onServiceAction={() => onServiceActionHandler(pick)}
            onLikeAction={() => onLikeActionHandler(pick)}
            onCommentAction={() => onCommentActionHandler(pick)}
            onShareAction={() => sharePick(pick)}
          />
        </div>
      ))}
    </div>
  );
};

const QuaPlaceHeader = ({}) => <></>;

const QuaBottomFloatingBar = ({ visible, onGotoApp }) => {
  if (!visible) return null;
  return (
    <>
      <div className={styles.bottom_fb_shadow}></div>
      <div className={styles.bottom_fb}>
        <div className={styles.contents}>
          <div className={styles.main}>
            <img
              className={styles.ic_appicon_32}
              src="/img/ic-appicon-32.png"
              srcSet="/img/ic-appicon-32@2x.png 2x,
                        /img/ic-appicon-32@3x.png 3x"
            />
            <div className={styles.app_title}>
              <h3 className={`Textstyle_6`} onClick={onGotoApp}>
                쿠아바타 앱으로 보기
              </h3>
            </div>
            <button className={`Textstyle_6`} onClick={onGotoApp}>
              이동
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const QuaPlaceFooter = ({ floatingMode, onGotoApp, onStayHere }) => {
  return (
    <>
      <BottomSheet visible={!floatingMode}>
        <QuaAppViewSheet
          visible={!floatingMode}
          onGotoApp={onGotoApp}
          onStayHere={onStayHere}
        />
      </BottomSheet>
      <QuaBottomFloatingBar visible={floatingMode} onGotoApp={onGotoApp} />
    </>
  );
};

const QuaPlace = ({}) => {
  const appContext = useContext(AppContext);
  const [webMode, setWebMode] = useState(appContext.webMode);
  const [appLinkPath] = useState("/eg_place");
  const appGuideDialog = {};
  const serviceDialog = {};
  const communiDialog = {};

  const onInformApp = () => {
    setWebMode((appContext.webMode = false));
  };

  const onStayHere = () => {
    setWebMode((appContext.webMode = true));
  };

  useEffect(() => {
    if (appContext.viewCount >= 5) {
      setWebMode((appContext.webMode = false));
      appContext.viewCount = 0;
    }
  }, []);

  return (
    <>
      <QuaAppBar
        onShowAppGuide={() => appGuideDialog.open(appLinkPath)}
        onGotoApp={() => onGotoApp(appLinkPath)}
      />
      <div className={styles.web_place_main}>
        <QuaPlaceHeader />
        <div className={styles.body}>
          <QuaPlaceBody
            serviceDialog={serviceDialog}
            likeDialog={communiDialog}
            commentDialog={communiDialog}
            onShowAd={onInformApp}
          />
        </div>
        <QuaPlaceFooter
          floatingMode={webMode}
          onGotoApp={() => onGotoApp(appLinkPath)}
          onStayHere={onStayHere}
        />
      </div>
      <QuaAppGuideDialog dialog={appGuideDialog} />
      <QuaServiceDialog dialog={serviceDialog} />
      <QuaCommuniDialog dialog={communiDialog} />
    </>
  );
};

export default QuaPlace;
