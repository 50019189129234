import styles from "./index.module.css";
import React from "react";

const QuaAppBar = ({ visible = true, onShowAppGuide, onGotoApp }) => {
  return visible ? (
    <>
      <header className={styles.app_bar} aria-hidden={false}>
        <div className={styles.app_bar_pos}>
          <div className={styles.body}>
            <img
              className={styles.log_symbol_20}
              src="/img/ic-appicon-20.png"
              srcSet="/img/ic-appicon-20@2x.png 2x,
                    /img/ic-appicon-20@3x.png 3x"
              onClick={onShowAppGuide}
            />
            <img
              className={styles.log_typo}
              src="/img/ic-apptypo.svg"
              onClick={onShowAppGuide}
            />
            <button className={`Textstyle_6`} onClick={onGotoApp}>
              앱으로 보기
            </button>
          </div>
        </div>
      </header>
      <div className={styles.app_bar_shadow}></div>
    </>
  ) : null;
};

export default QuaAppBar;
