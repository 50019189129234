import React, { useEffect } from "react";

function BottomSheet({ visible, children }) {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);
  return visible ? (
    <div className="sheet" role="dialog">
      <div className="overlay"></div>
      <div className="contents">
        <header className="controls"></header>
        <main className="body">{children}</main>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default BottomSheet;
