import styles from "./QuaPickTileFooter.module.css";
import React from "react";

function QuaPickTileFooter({
  seqNo,
  likeNum,
  commentNum,
  onLikeAction,
  onCommentAction,
  onShareAction,
}) {
  return (
    <div className={styles.pick_like_wrap}>
      <div className={`${styles.pick_like} Textstyle_8`}>
        <img
          alt="좋아요"
          src="/img/ic-like-outline-off-color4-20.svg"
          onClick={onLikeAction}
        ></img>
        <span onClick={onLikeAction}>{likeNum}</span>
        <img
          className={styles.comment_icon}
          alt="댓글보기"
          src="/img/ic-comment-outline-off-color4-20.svg"
          onClick={onCommentAction}
        ></img>
        <span onClick={onCommentAction}>{commentNum}</span>
        <img
          className={styles.share_icon}
          alt="공유하기"
          src="/img/ic-share-color4-20.svg"
          onClick={onShareAction}
        ></img>
      </div>
    </div>
  );
}

export default QuaPickTileFooter;
