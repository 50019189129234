import moment from "moment";

export function formatDate(date) {
  const dt = moment(date);
  const now = moment();
  if (now.diff(dt, "days") > 0) {
    return dt.format("YYYY.MM.DD");
  } else if (now.diff(dt, "hours") > 0) {
    return `${now.diff(dt, "hours")}시간 전`;
  } else if (now.diff(dt, "minutes") > 0) {
    return `${now.diff(dt, "minutes")}분 전`;
  } else {
    return "방금 전";
  }
}
