import styles from "./QuaPick.module.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../common/date";

import MyTalkBox from "../components/MyTalkBox";
import TalkBox from "../components/TalkBox";
import {
  QuaAppGuideDialog,
  QuaCommuniDialog,
  QuaServiceDialog,
} from "../components/QuaAppDownDialog";
import { sharePick } from "../common/share";
import BottomSheet from "../components/BottomSheet";
import QuaAppViewSheet from "../components/QuaAppViewSheet";
import AppContext from "../context/AppContext";
import { onGotoApp } from "../App";
import QuaAppBar from "../components/QuaAppBar";

const QuaPickTileHeader = ({ pick, onServiceAction }) => {
  function imageSetOf(aimg) {
    if (!aimg) return "";
    let dotIdx = aimg.lastIndexOf(".");
    return dotIdx
      ? `${aimg.slice(0, dotIdx)}@2x${aimg.slice(dotIdx)} 2x, ` +
          `${aimg.slice(0, dotIdx)}@3x${aimg.slice(dotIdx)} 3x`
      : "";
  }

  return (
    <>
      <div className={styles.pick_title}>
        <h1 className="Textstyle_0">{pick.title}</h1>
      </div>
      <div className={`${styles.service_info} Textstyle_8`}>
        <img src="/img/ic-arrow-right-color5-12.svg" />
        <span className={`${styles.ellipse}`} onClick={onServiceAction}>
          {pick.sowner && pick.sowner.anm}
        </span>
        <span className={`${styles.sp}`} onClick={onServiceAction}>
          님의
        </span>
        <span className={`${styles.ellipse}`} onClick={onServiceAction}>
          {pick.snm}
        </span>
        <span onClick={onServiceAction}>&nbsp;사용</span>
      </div>
      <div className={styles.pick_writer}>
        <img
          alt="avatar"
          className={styles.avatar_img}
          src={pick.aimg}
          srcSet={imageSetOf(pick.aimg)}
        />
        <div className={styles.avatar_text}>
          <span className={`${styles.avatarid} Textstyle_7`}>{pick.anm}</span>
          <span className={`${styles.date} Textstyle_8`}>
            {formatDate(pick.when)}
          </span>
        </div>
      </div>
    </>
  );
};

const QuaPickTileFooter = ({ pick, onLikeAction, onCommentAction }) => {
  const [likeNum, setLikeNum] = useState(pick.like_num);
  const [commentNum, setCommentNum] = useState(pick.comment_num);

  useEffect(() => {
    setLikeNum(pick.like_num);
    setCommentNum(pick.comment_num);
  }, [pick]);

  return (
    <>
      <footer className={styles.pick_footer}>
        <hr className={styles.pick_footer_sp} />
        <div className={styles.pick_like_wrap}>
          <div className={`${styles.pick_like} Textstyle_8`}>
            <img
              alt="좋아요"
              src="/img/ic-like-outline-off-color4-20.svg"
              width="24"
              onClick={onLikeAction}
            ></img>
            <span onClick={onLikeAction}>{likeNum}</span>
            <img
              className={styles.comment_icon}
              alt="댓글보기"
              src="/img/ic-comment-outline-off-color4-20.svg"
              width="24"
              onClick={onCommentAction}
            ></img>
            <span onClick={onCommentAction}>{commentNum}</span>
            <img
              className={styles.share_icon}
              alt="공유하기"
              src="/img/ic-share-color4-20.svg"
              width="24"
              onClick={() => sharePick(pick)}
            ></img>
          </div>
        </div>
      </footer>
      <div className={styles.pick_footer_shadow}></div>
    </>
  );
};

const QuaPickTileBody = ({ pick }) => {
  const [chats, setChats] = useState([]);

  useEffect(() => {
    if (pick.pickSeqNo) {
      setChats(
        pick.chats.map((chat) => {
          chat.itsme = chat.aid === pick.aid;
          return chat;
        })
      );
    }
  }, [pick]);

  return (
    <div className={styles.pick_chatbox_area}>
      <div className={styles.pick_chatbox}>
        {chats.map((chat, index) => {
          const aid =
            index === 0
              ? chat.aid
              : chat.aid !== chats[index - 1].aid
              ? chat.aid
              : null;
          const anm = aid ? chat.anm : null;
          if (chat.itsme) {
            return <MyTalkBox key={index} message={chat.cont} />;
          } else {
            return (
              <TalkBox key={index} aid={aid} anm={anm} message={chat.cont} />
            );
          }
        })}
      </div>
    </div>
  );
};

const QuaPickMore = ({ pick, onClickPick }) => {
  const lastPickSeqNo = useRef("");
  const loadingMoreApi = useRef(false);
  const observerRef = useRef(null);
  const pageMoreRef = useRef(null);
  const [pickMore, _setPickMore] = useState([]);
  const pickMoreRef = useRef(pickMore);
  const setPickMore = (data) => {
    pickMoreRef.current = data;
    _setPickMore(data);
  };

  useEffect(() => {
    const fetchPicks = async (handler) => {
      if (loadingMoreApi.current) return;
      loadingMoreApi.current = true;
      const apiUri = `https://api-dev.quavatar.com/smiled/pick-list?startFrom=${lastPickSeqNo.current}&limit=60`;
      try {
        const response = await axios.get(apiUri);
        const stdDto = response.data;
        if (stdDto.resultCode === "EO101") {
          handler(stdDto.result);
        } else {
          console.error(stdDto.resultCode);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const backgroundTemplates = [
      "rgba(175, 82, 222, 0.05)",
      "rgba(90, 200, 250, 0.05)",
      "rgba(88, 86, 214, 0.05)",
      "rgba(0, 122, 255, 0.05)",
    ];

    const hashCode = (s) =>
      s.split("").reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);

    const backgroundColor = (seqNo, title) => {
      const modular = backgroundTemplates.length;
      let code = ((hashCode(seqNo + title) % modular) + modular) % modular;
      return backgroundTemplates[code];
    };

    const loadedQuaPickList = async (pickList) => {
      setPickMore([
        ...pickMoreRef.current,
        ...pickList.map((pick) => {
          lastPickSeqNo.current = pick.pickSeqNo;
          return {
            pickSeqNo: pick.pickSeqNo,
            title: pick.title,
            chat: pick.chats[0].cont,
            background: backgroundColor(pick.pickSeqNo, pick.title),
          };
        }),
      ]);

      loadingMoreApi.current = false;
    };

    observerRef.current = new IntersectionObserver(
      ([ent]) => ent.isIntersecting && fetchPicks(loadedQuaPickList),
      {
        rootMargin: "0px 0px -87px",
        threshold: 1,
      }
    );
    observerRef.current.observe(pageMoreRef.current);
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [pick]);

  return (
    <div className={styles.more_wrap}>
      <h2 className={`Textstyle_2`}>
        쿠아바타 <span>픽 더보기</span>
      </h2>
      <div className={styles.more}>
        {pickMore.map((pick, index) => (
          <div
            className={styles.thumb}
            style={{ backgroundColor: pick.background }}
            key={index}
            onClick={(event) => {
              event.preventDefault();
              onClickPick(pick.pickSeqNo);
            }}
          >
            <h3 className={`${styles.thumb_t} Textstyle_6`}>{pick.title}</h3>
            <p className={`${styles.thumb_b} Textstyle_8`}>{pick.chat}</p>
          </div>
        ))}
      </div>
      <div className={styles.page_more} ref={pageMoreRef}></div>
    </div>
  );
};

const QuaPick = () => {
  const navigate = useNavigate();
  const { seq_no } = useParams();
  const appContext = useContext(AppContext);
  const [webMode, setWebMode] = useState(appContext.webMode);
  const [appLinkPath, setAppLinkPath] = useState("/");
  const [pick, setPick] = useState({
    title: "",
    aid: "",
    aimg: "",
    anm: "",
    pickTime: "",
    chats: [],
    liked: false,
    like_num: 0,
  });
  const appGuideDialog = {};
  const serviceDialog = {};
  const communiDialog = {};

  useEffect(() => {
    const fetchPick = async (handler) => {
      const apiUri = `https://api-dev.quavatar.com/smiled/pick/pick/${seq_no}`;
      try {
        const response = await axios.get(apiUri);
        const stdDto = response.data;
        if (stdDto.resultCode === "EO101") {
          appContext.viewCount++;
          handler(stdDto.result);
        } else if (stdDto.resultCode === "EO100") {
          navigate("/pick/not-found");
        } else {
          navigate("/place");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const loadedQuaPick = async (pick) => {
      const _avatarIdMap = {};
      pick.chats.map(
        (chat) =>
          !(chat.aid in _avatarIdMap) &&
          (_avatarIdMap[chat.aid] = { aid: chat.aid, anm: chat.anm })
      );
      const so = { aid: 1 * pick.sid };
      setPick({
        ...pick,
        sowner: _avatarIdMap[so.aid],
        aimg: "/img/avatar-cli.png",
      });
      setAppLinkPath("/eg_place/" + seq_no);
    };

    seq_no && fetchPick(loadedQuaPick);
    if (appContext.viewCount >= 4) {
      setWebMode((appContext.webMode = false));
      appContext.viewCount = 0;
    }
  }, [seq_no]);

  const onServiceAction = () => {
    serviceDialog.open(appLinkPath);
  };
  const onLikeAction = () => {
    communiDialog.open(appLinkPath);
  };
  const onCommentAction = () => {
    communiDialog.open(appLinkPath);
  };
  const onStayHere = () => {
    setWebMode((appContext.webMode = true));
  };
  const onClickPick = (pickSeqNo) => {
    navigate(`/pick/${pickSeqNo}`);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <QuaAppBar
        onShowAppGuide={() => appGuideDialog.open(appLinkPath)}
        onGotoApp={() => onGotoApp(appLinkPath)}
      />
      <div className={styles.pick_wrap}>
        <QuaPickTileHeader pick={pick} onServiceAction={onServiceAction} />
        <QuaPickTileBody pick={pick} />
      </div>
      <hr className={styles.more_sp} />
      <QuaPickMore pick={pick} onClickPick={onClickPick} />
      <QuaPickTileFooter
        pick={pick}
        onLikeAction={onLikeAction}
        onCommentAction={onCommentAction}
      />
      <BottomSheet visible={!webMode}>
        <QuaAppViewSheet
          visible={!webMode}
          onGotoApp={() => onGotoApp(appLinkPath)}
          onStayHere={onStayHere}
        />
      </BottomSheet>
      <QuaAppGuideDialog dialog={appGuideDialog} />
      <QuaServiceDialog dialog={serviceDialog} />
      <QuaCommuniDialog dialog={communiDialog} />
    </>
  );
};

export default QuaPick;
