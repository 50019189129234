import styles from "./QuaPickTileBody.module.css";
import React from "react";

import MyTalkBox from "../MyTalkBox";
import TalkBox from "../TalkBox";

function QuaPickTileBody(props) {
  const { chats, pickType, onClick } = props;
  const clickHandler = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };
  return (
    <div
      className={
        pickType === "large"
          ? styles.pick_chatbox_area_large
          : styles.pick_chatbox_area_normal
      }
    >
      <div
        className={`pick_chatbox_it ${styles.pick_chatbox}`}
        onClick={clickHandler}
      >
        {chats.map((chat, index) => {
          const aid =
            index === 0
              ? chat.aid
              : chat.aid !== chats[index - 1].aid
              ? chat.aid
              : null;
          const anm = aid ? chat.anm : null;
          if (chat.itsme) {
            return <MyTalkBox message={chat.cont} key={index} />;
          } else {
            return (
              <TalkBox aid={aid} anm={anm} message={chat.cont} key={index} />
            );
          }
        })}
      </div>
    </div>
  );
}

export default QuaPickTileBody;
